 export const items = [
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1odyhBVvo-hg9TWpyqc93Hlf1dJLa0KCc&sz=w1000',
      metaData: {
        type: 'image',
        height: 533,
        width: 800,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1sSP5d23cSDG6jHANbWO47bMYhC7NvH1J&sz=w1000',
      metaData: {
        type: 'image',
        height: 640,
        width: 960,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1ALTZ3BLqRjcmtyqlxVmjK-VQpy1uWPvR&sz=w1000',
      metaData: {
        type: 'image',
        height: 960,
        width: 640,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1Mh72oS1r20eFizb_Y9ACXue0wDjBsyX4&sz=w1000',
      metaData: {
        type: 'image',
        height: 900,
        width: 600,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=11EfeEUelqA4MAYWPPLhh1FXnpN-YxkTw&sz=w1000',
      metaData: {
        type: 'image',
        height: 533,
        width: 800,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1tD4QHazGyrchIto4tLm-2wkWUBAfLtZ_&sz=w1000',
      metaData: {
        type: 'image',
        height: 797,
        width: 800,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1G9l42p_VZLewArkDeANsgMH5uarBuQlC&sz=w1000',
      metaData: {
        type: 'image',
        height: 789,
        width: 800,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1_22BtABk9_eLp1TvvGiN7XkD1hrVrEnZ&sz=w1000',
      metaData: {
        type: 'image',
        height: 640,
        width: 960,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1F-wDIb5zAlu7gJXgIhgAm7hZjejG7CTe&sz=w1000',
      metaData: {
        type: 'image',
        height: 804,
        width: 800,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1cb4eW6jZ6JKOee8F0EDvJWQT7hEIZ76x&sz=w1000',
      metaData: {
        type: 'image',
        height: 960,
        width: 640,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1vBxfNAnJfntTwRf1-MzwBdajdj9OB2Pb&sz=w1000',
      metaData: {
        type: 'image',
        height: 806,
        width: 1200,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
    { // Image item:
      itemId: 'sample-id',
      mediaUrl: 'https://drive.google.com/thumbnail?id=1oK2FAl9YgWxjOgQPaKJOQeaw9JVFO1w1&sz=w1000',
      metaData: {
        type: 'image',
        height: 640,
        width: 960,
        title: 'sample-title',
        description: 'sample-description',
        focalPoint: [0, 0],
        link: {
          url: 'http://example.com',
          target: '_blank'
        },
      }
    },
  ]
