import { graphql } from 'gatsby'
import styled from "@emotion/styled"
import React from 'react'
import Layout from '../components/layout'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { selectHomepage, selectFields, selectImages } from "../interface/api-to-component"
import { 
	PRISMIC_TYPENAME_HOMEPAGE_CATERING
} from "../utils/prismic"
import About from "../components/about"
import CallToAction from "../components/call-to-action"
import CallToActionSecondary from "../components/call-to-action-secondary"
import CallToActionFooter from "../components/call-to-action-footer"
import Contact from "../components/contact"
import Grid from "../components/grid"
import MenuItem from "../components/menu-item"
import SEO from "../components/seo"
import imgCheckered from "../images/checkered-14.png"
import imgFG from "../images/fg-texture.jpg"
import imgBG from "../images/bg-texture-narrow.jpg"
import ImageLogoLucilles from "../components/image-logo-lucilles"

import LogoCateringGold from "../components/image-logo-catering-gold"
import LogoTraiteurGold from "../components/image-logo-traiteur-gold"

import viewportBG from "../images/catering-oyster-shrimp-display.jpg"

import { ProGallery, ProGalleryRenderer } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';

import { items } from '../data/items'
import useWindowDimensions from "../hooks/window-dimensions" 

import { 
  INDEX_PAGEKEYS_ABOUT,
  INDEX_PAGEKEYS_GALLERY,
} from "../data/assets"

import { page_links, social_links, external_links } from "../data/assets"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons"

export const query = graphql`
  query StringTranslations($lang: String) {
    prismic {
			allHomepage_caterings(lang: $lang) {
				edges {
					node {
						body {
								... on PRISMIC_Homepage_cateringBodyImage_grid {
									fields {
										image
									}
								}
							... on PRISMIC_Homepage_cateringBodyHero_section {
								primary {
									hero_image
									hero_headline
									hero_summary
                  hero_call_to_action_label
                  hero_call_to_action_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
								}
							}
						}
						summary
						title
					}
				}
			}
			allCall_to_actions(lang: $lang) {
				edges {
					node {
						_meta {
							lang
						}
						headline
						label
						link {
							... on PRISMIC__ExternalLink {
								url
							}
						}
					}
				}
			}
    }
  }
`;

const Hero = styled.div`
	color: #fff;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100vh;
  }
  @media (min-width: 768px) and (min-height: 700px) {
    position: relative;
    height: 100vh;
  }
`
const HeroPanel = styled.div`
  margin: -240px 10px 0px 10px;
  opacity: 0.96;
  background-image: url(${imgBG});
  border-radius: 4px;
  padding: 20px;
  bottom: 40px;
  left: 40px;
  max-width: 550px;
  text-shadow: 4px 4px 4px rgba(0, 34, 96, 0.41);
  text-shadow: 4px 4px 4px #222;
  h1 {
    margin: 4px;
    font-size: 34px;
    font-family: 'Anzeigen Grotesk W01_705598';
    font-weight: normal;
    letter-spacing: 4px;
  }
  h2 {
    font-size: 1.5rem;
    font-family: 'Bebas Neue';
  }
  h3 {
    font-size: 1.2rem;
    font-family: 'Bebas Neue';
  }
	p {
		margin: 0 0 14px 0;
    font-size: 1rem;
    color: #b2b2b2;
	}
  @media (min-width: 768px) and (min-height: 700px) {
    position: absolute;
  }
  @media (min-width: 1024px) {
    left: 6vw;
  }
  @media (min-width: 1600px) {
  }
  @media (min-height: 800px) {
    bottom: 6vh;
  }
  @media (max-width: 768px) {
    margin: -240px 10px 40px 10px;
  }
`
const Section = styled.section`
  border-top: 1px solid #dab069;
  max-width: 100%;
  display: flex;
  position: relative;
  margin:0;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
  }
  @media (min-width: 1024px) {
  }
  h2 {
    margin-bottom: 20px;
    @media (min-width: 1024px) {
      margin-bottom: 40px;
    }
  }
`
const Container = styled.div`
  background-image: url(${imgFG});
  border-radius: 4px;
  padding: 20px;
  margin: auto;
  @media (min-width: 640px) {
    margin: 2rem auto;
  }
  @media (min-width: 768px) {
    margin: 4rem auto;
  }
  @media (min-width: 1024px) {
    margin: 8rem auto;
  }
  max-width: 640px;
`
const BWOut = styled.div`
  border: 1px solid #dab069;
  border-radius: 2px;
  padding: 1px;
`
const BWIn = styled.div`
  border: 1px solid #dab069;
  padding: 0.7rem 2rem 3rem 2rem;
  @media (min-width: 768px) {
    padding: 1rem 3rem 2rem 3rem;
  }
`
const GridPanel = styled.div`
  display: none;
  position: absolute;
  bottom: 120px;
  right: -10vw;
  .gatsby-image-wrapper {
    height: 43px;
    width: 120px;
    @media (min-width: 1024px) {
      height: 43px;
      width: 120px;
    }
    @media (min-width: 1600px) {
      height: 44px;
      width: 120px;
    }
  }
  @media (min-width: 1024px) {
    display: block;
  }
  @media (min-width: 1600px) {
  }
`
const HeroMask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${imgCheckered});
`
const BookingBar = styled.div`
  position: absolute;
  top: 120px;
  right: 0;
  z-index: 999;
  box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, .2);
  background-image: url(${imgFG});
  @media (min-width: 480px) {
    position: absolute;
    top: 180px;
  }
`
const Outline = styled.div`
  border-top: 1px solid #00aad4;
  border-left: 1px solid #00aad4;
  border-bottom: 1px solid #00aad4;
  margin: 4px 0 4px 4px;
`
const Inline = styled.div`
  border-top: 1px solid #00aad4;
  border-left: 1px solid #00aad4;
  border-bottom: 1px solid #00aad4;
  margin: 1px 0 1px 1px;
`
const Anchor = styled.a`
  font-size: 12px;
  padding: 10px 30px;
  text-decoration: none;
`
const AddressBar = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin-bottom: 20px;
  padding: 4px 0;
  font-size: 12px;
  color: #fff;
  background-image: url(${imgFG});
  box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, 0.2);
`
const Summary = styled.div`
  a {
    font-family: 'Oswald';
    color: #e2e2e2;
    border-bottom: 2px solid #fff;
    text-decoration: none;
  }
  a:hover {
    border-bottom: 2px solid #dab069;
  }
  @media (min-width: 768px) {
  }
`
const Left= styled.div`
  width: 30%;
  @media (min-width: 380px) {
    width: 45%;
  }
  @media (min-width: 480px) {
    width: 20%;
  }
`
const Right = styled.div`
  text-align: right;
  width: 70%;
  @media (min-width: 380px) {
    width: 55%;
  }
  @media (min-width: 480px) {
    width: 80%;
  }
`
const BG = styled.div`
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  @media (min-width: 768px) and (min-height: 700px) {
    position: absolute;
  }
`
const IMG = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition-delay: 500ms;
`
const Frame = styled.div`
  .gatsby-image-wrapper {
    margin: auto;
    max-height: 160px;
    max-width: 240px;
  }
`
const BlueBar = styled.div`
	background-color: #00ABD4;
	height: 4px;
	width:100%;
	margin: 10px auto;
`
const BlueBarThin = styled.div`
	background-color: #00ABD4;
	height: 2px;
	width:100%;
	margin: 4px auto;
`
const GreyBar = styled.div`
	background-color: #414141;
	height: 4px;
	width:100%;
	margin: 10px auto;
`
const BlackBar = styled.div`
	background-color: #1C1A1A;
	height: 4px;
	width:100%;
	margin: 10px auto;
`
const WhiteBar = styled.div`
	background-color: #FFF;
	height: 4px;
	width:100%;
	margin: 10px auto;
`
const Widget = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 30px;
  color: #dab069;
  font-size: 3em;
`
const DoubleBar = () => (
	<>
		<BlueBar style={{marginBottom: 4}} />
		<GreyBar style={{marginTop: 0}} />
	</>
)
const DoubleWhiteBar = () => (
	<>
		<WhiteBar style={{marginBottom: 4}} />
		<WhiteBar style={{marginTop: 0}} />
	</>
)

const hasWindow = typeof window !== 'undefined'

const Page = (props) => {

	const allHomepage = props.data.prismic.allHomepage_caterings
	const allCall = props.data.prismic.allCall_to_actions
  const { width } = useWindowDimensions()

  const sectionPadding = width < 1024 ? '20px 0' : '20px 0 150px 0'

  if(!allHomepage.edges[0])
    return null
  if(!allCall.edges[0])
    return null

  const title = get(allHomepage, 'edges.0.node.title', [])
  const summary = get(allHomepage, 'edges.0.node.summary', [])
  const contact_section = get(allHomepage, 'edges.0.node.contact_section', [])
  const description = get(allHomepage, 'edges.0.node.description', '')
  const lang = get(props.pageContext, 'lang', '')

  const homepage =  selectHomepage(
    props.data, PRISMIC_TYPENAME_HOMEPAGE_CATERING)

  const images =  selectImages(
    props.data, PRISMIC_TYPENAME_HOMEPAGE_CATERING)

  const headline = get(allCall, 'edges.0.node.headline', [])
  const label = get(allCall, 'edges.0.node.label', '')
  const link = get(allCall, 'edges.0.node.link.url', '')

  const locale = lang === 'en-ca' ? 'en' : 'fr'

  const Image = 
      lang === 'en-ca' 
        ? <LogoCateringGold /> 
        : <LogoTraiteurGold />

  let galleryPadding = 0
  if(width > 1024 && width < 1599) {
    galleryPadding = 300
  } else if ( width >= 1600  && width < 2399) {
    galleryPadding = 800
  } else if ( width >= 2400 ) {
    galleryPadding = 1200
  }

  const galleryWidth = width - galleryPadding

  const container = {
    width: galleryWidth,
    height: 500,
  }

  const Gallery =
    hasWindow 
      ? ProGallery
      : ProGalleryRenderer;
  
  const options = {
    galleryLayout: 0,
    infiniteScroll: true,
    hoveringBehaviour: 'NEVER_SHOW',
  }

  return (
    <Layout 
      lang={lang} 
      call_to_action_label={homepage.hero_call_to_action_label}
      call_to_action_link={homepage.hero_call_to_action_link.url}
      page_links={page_links}
      social_links={social_links}
    >
      <SEO 
				lang={lang}
        title={RichText.asText(title)} 
        description={description}
      />
      <Hero>
        <BG><IMG src={viewportBG} alt={homepage.hero_image.alt} /></BG>
        <Widget>
          <FontAwesomeIcon icon={faCaretDown}/>
        </Widget>
        <HeroMask />
        { /*
        <HeroPanel ref={page_links[locale][INDEX_PAGEKEYS_ABOUT].ref}>
          <BWOut>
            <BWIn>
              <Frame>
                { Image }
              </Frame>
              <Summary>
              { RichText.render(summary) }
              </Summary>
              <CallToAction 
                label="Contact"
                link="mailto:allix@lucillesoyster.com"
              />
              <CallToActionSecondary
                label={external_links[locale][0].label}
                link={external_links[locale][0].link}
              />
              <CallToActionSecondary
                label={external_links[locale][1].label}
                link={external_links[locale][1].link}
              />
            </BWIn>
          </BWOut>
        </HeroPanel>
        <GridPanel>
          <Grid images={images} />
        </GridPanel>
        */ }
      </Hero>

      <Section ref={page_links[locale][INDEX_PAGEKEYS_ABOUT].ref}>
        <Container> 
          <BWOut>
            <BWIn>
              <Frame>
                { Image }
              </Frame>
              <Summary>
              { RichText.render(summary) }
              </Summary>
              <CallToAction 
                label="Contact"
                link="mailto:catering@lucillesoyster.com"
              />
              <CallToActionSecondary
                label={external_links[locale][0].label}
                link={external_links[locale][0].link}
              />
              <CallToActionSecondary
                label={external_links[locale][1].label}
                link={external_links[locale][1].link}
              />
            </BWIn>
          </BWOut>
        </Container>
      </Section>

      <Section ref={page_links[locale][INDEX_PAGEKEYS_GALLERY].ref} style={{padding: sectionPadding, margin: 'auto', maxWidth: galleryWidth}}>
          <Gallery
            domId="gallery-ssr"
            items={items}
            container={container}
            isPrerenderMode={!hasWindow}
            options={options}
          />
      </Section>

    { /*
      <CallToActionFooter
        headline={headline}
        label={label}
        link={link}
      />
    */ }

    </Layout>
  );
}

export default Page
