import React from "react"
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"

export const social_links = [
  {
    name: "Facebook",
    href: "//www.facebook.com/pages/Lucilles-Oyster-Dive/200515528427",
    icon: faFacebook
  },
  {
    name: "Instagram",
    href: "//www.instagram.com/lucillescatering/",
    icon: faInstagram
  }
]

export const page_links = {
  en: [
    {
      name: "About",
      ref: React.createRef(),
    },
    {
      name: "Gallery",
      ref: React.createRef(),
    },
  ],
  fr: [
    {
      name: "À propos",
      ref: React.createRef(),
    },
    {
      name: "Gallerie",
      ref: React.createRef(),
    },
  ]
}

export const external_links = {
  en: [
    {
      label: "Lucille's Food Truck",
      link: "//foodtruck.lucillesoyster.com/en/",
    },
    {
      label: "Lucy's Lemonade Truck",
      link: "//www.lucyslemonade.com/en/",
    },
  ],
  fr: [
    {
      label: "Camion Lucille's",
      link: "//foodtruck.lucillesoyster.com/",
    },
    {
      label: "Camion Lucy's",
      link: "//www.lucyslemonade.com/",
    },
  ]
}

export const INDEX_PAGEKEYS_ABOUT   = 0
export const INDEX_PAGEKEYS_GALLERY = 1
export const INDEXLEN_PAGEKEYS      = 2
