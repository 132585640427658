import React from "react"
import styled from "@emotion/styled"
import { RichText } from 'prismic-reactjs'

const Footer = styled.div`
  display: inline;
  padding: 10px 10px 0 0;
  margin-bottom: 10px;
  @media (min-width: 768px) {
  }
  @media (min-width: 1024px) {
  }
`
const Footline = styled.div`
  max-width: 600px;
  padding-right: 50px;
  @media (min-width: 1024px) {
  }
`
const ButtonLink = styled.a`
  text-shadow: none;
  display: inline-block;
  color: #fff;
  font-family: 'Oswald';
  font-size: 16px;
  border-bottom: 2px solid #e2e2e2;
  &:visited {
    color: #fff;
  }
  &:hover {
    border-bottom-color: #ba9049;
  }
  @media (min-width: 1024px) {
    bottom: 20px;
    right: 10px;
  }
`

const CallToAction = ({ headline, label, link }) => {
  return (
    <Footer>
      { headline && 
        <Footline>
          { RichText.render(headline) }
        </Footline>
      }
      <ButtonLink href={link}>{ label }</ButtonLink>
    </Footer>
  )
}

export default CallToAction
