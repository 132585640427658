import React from "react"
import styled from "@emotion/styled"
import { RichText } from 'prismic-reactjs'

import imgBG from "../images/bg-texture.jpg"

const Footer = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #333;
  padding: 40px;
  background-image: url(${imgBG});
  p {
    color: #b2b2b2;
    margin-bottom: 20px;
  }
  @media (min-width: 480px) {
    flex-flow: nowrap;
    p {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1024px) {
    padding: 60px;
    margin-top: 4rem;
  }
`
const Footline = styled.div`
  max-width: 600px;
  text-align: center;
  @media (min-width: 480px) {
    padding-right: 30px;
    text-align: left;
  }
  @media (min-width: 1024px) {
    padding-right: 50px;
  }
`
const Button = styled.a`
  text-align: center;
  min-width: 110px;
  display: inline-block;
  background-color: #fff;
  padding: 8px 12px;
  color: #060809;
  font-family: 'Oswald';
  font-size: 16px;
  border-bottom:2px solid #333;
  border-right:2px solid #333;
  &:visited {
    color: #060809;
  }
  &:hover {
    color: #00ABD4;
    border-bottom:2px solid #00ABD4;
    border-right:2px solid #00ABD4;
  }
  @media (min-width: 1024px) {
    padding: 8px 24px;
    bottom: 20px;
    right: 10px;
  }
`

const CallToActionFooter = ({ headline, label, link, footer, footline, button }) => {
  return (
    <Footer css={footer}>
      <Footline css={footline}>
        { RichText.render(headline) }
      </Footline>
      <Button css={button} href={link}>{ label }</Button>
    </Footer>
  )
}

export default CallToActionFooter
