import React from "react"
import styled from "@emotion/styled"

const FlexItem = styled.div`
  flex: 50%;
  padding: 10px;
  @media (min-width: 768px) {
    flex: 20%;
  }
  @media (min-width: 1600px) {
  }
`
const Frame = styled.div`
  position: relative;
  flex: 1;
  border-bottom: 4px solid #414141;
`
const Container = styled.div`
  position: relative;
  right: -400px;
  display: flex;
  flex-flow: wrap row;
  & div:last-child {
  }
  @media (min-width: 768px) {
    flex-flow: nowrap row;
  }
`
const Image = styled.img`
  display: block;
  margin-bottom: 4px;
  padding: 0;
  border-bottom: 4px solid #00ABD4;
`
const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const Frames = ({images}) => {
  let items = []
  if(images) {
    items = images.map(e => (
      <FlexItem>
        <Frame key={e.image.url + e.image.alt}>
          <Mask />
          <Image src={e.image.url} alt={e.image.alt}/>
        </Frame>
      </FlexItem>
    ))
  }

  return items
}
const Grid = ({images}) => (
  <Container>
    <Frames images={images}/>
  </Container>
)

export default Grid
